import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

export class Link {
  url: string;
  label: string;
}
@Component({
  selector: 'app-unlock-account',
  templateUrl: './unlock-account.component.html',
  styleUrls: ['./unlock-account.component.sass']
})
export class UnlockAccountComponent implements OnInit {

  links = [] as Link[];
  constructor(private router: Router) {
    this.links = [{
      url: '01',
      label: 'START'
    }, {
      url: '02',
      label: 'STEP 01'
    }, {
      url: '03',
      label: 'STEP 02'
    }, {
      url: '04',
      label: 'STEP 03'
    }, {
      url: '05',
      label: 'STEP 04'
    }, {
      url: '06',
      label: 'DONE'
    },

    ];
  }

  ngOnInit() {
  }

  getActiveRoute() {
    const url = this.router.url;
    const splitLength = url.split('/').length;
    return url.split('/')[splitLength - 1];
  }

  getActiveRouteNumber() {
    return Number(this.getActiveRoute());
  }
}
