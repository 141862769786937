import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unlock-account-s6',
  templateUrl: './unlock-account-s6.component.html',
  styleUrls: ['./unlock-account-s6.component.sass']
})
export class UnlockAccountS6Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  unlockLink(): void {
    window.location.href = 'https://passwordreset.microsoftonline.com/';
  }

}
