import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unlock-account-s2',
  templateUrl: './unlock-account-s2.component.html',
  styleUrls: ['./unlock-account-s2.component.sass']
})
export class UnlockAccountS2Component implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }
  buttonClick(): void {
    this.router.navigateByUrl('/pages/password-reset/unlock-account/03');
  }

}
