import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-modify-registration-s3',
  templateUrl: './user-modify-registration-s3.component.html',
  styleUrls: ['./user-modify-registration-s3.component.sass']
})
export class UserModifyRegistrationS3Component implements OnInit {

  constructor(private router: Router) { }
  ngOnInit() {
  }

  buttonClick(): void {
    this.router.navigateByUrl('/pages/user-registration/mtr/04');
  }
}
