import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unlock-account-s3',
  templateUrl: './unlock-account-s3.component.html',
  styleUrls: ['./unlock-account-s3.component.sass']
})
export class UnlockAccountS3Component implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  buttonClick(): void {
    this.router.navigateByUrl('/pages/password-reset/unlock-account/04');
  }
}
