import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forgot-password-s11',
  templateUrl: './forgot-password-s11.component.html',
  styleUrls: ['./forgot-password-s11.component.sass']
})
export class ForgotPasswordS11Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  resetPassword(): void {
    window.location.href = 'https://passwordreset.microsoftonline.com/';
  }
}
