import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-registration-s8',
  templateUrl: './user-registration-s8.component.html',
  styleUrls: ['./user-registration-s8.component.sass']
})
export class UserRegistrationS8Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  registerLink(): void {
    window.location.href = 'https://aka.ms/ssprsetup?whr=kbr.com';
  }
}
