export class Link {
  url: string;
  label: string;
  label1: string;

}
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.sass']
})
export class ForgotPasswordComponent implements OnInit {

  links = [] as Link[];
  constructor(private router: Router) {
    this.links = [{
      url: '01',
      label: 'START',
      label1: '0'
    }, {
      url: '02',
      label: 'STEP 01',
      label1: '0'
    }, {
      url: '03',
      label: 'STEP 02',
      label1: '0'
    }, {
      url: '04',
      label: 'STEP 03',
      label1: 'OPTION 01'
    }, {
      url: '05',
      label: 'STEP 03',
      label1: ' OPTION 02'
    }, {
      url: '06',
      label: 'STEP 03 ',
      label1: 'OPTION 03'
    }, {
      url: '07',
      label: 'STEP 03 ',
      label1: 'OPTION 04'
    },
    {
      url: '08',
      label: 'STEP 03 ',
      label1: 'OPTION 05'
    },
    {
      url: '09',
      label: 'STEP 04',
      label1: '0'
    },
    {
      url: '10',
      label: 'STEP 05',
      label1: '0'
    },
    {
      url: '11',
      label: 'DONE',
      label1: '0'
    }];
  }

  ngOnInit() {

  }

  getActiveRoute() {
    const url = this.router.url;
    const splitLength = url.split('/').length;
    return url.split('/')[splitLength - 1];
  }

  getActiveRouteNumber() {
    return Number(this.getActiveRoute());
  }
}
