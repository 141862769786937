import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { UserRegistrationComponent } from './pages/user-registration/user-registration.component';
import { FirstTimeRegistrationComponent } from './pages/user-registration/first-time-registration/first-time-registration.component';
import { UserRegistrationS1Component } from './pages/user-registration/first-time-registration/user-registration-s1/user-registration-s1.component';
import { UserRegistrationS2Component } from './pages/user-registration/first-time-registration/user-registration-s2/user-registration-s2.component';

import { ModifyRegistrationComponent } from './pages/user-registration/modify-registration/modify-registration.component';
import { UserRegistrationS3Component } from './pages/user-registration/first-time-registration/user-registration-s3/user-registration-s3.component';
import { UserRegistrationS8Component } from './pages/user-registration/first-time-registration/user-registration-s8/user-registration-s8.component';
import { UserRegistrationS4Component } from './pages/user-registration/first-time-registration/user-registration-s4/user-registration-s4.component';
import { UserRegistrationS5Component } from './pages/user-registration/first-time-registration/user-registration-s5/user-registration-s5.component';
import { UserRegistrationS6Component } from './pages/user-registration/first-time-registration/user-registration-s6/user-registration-s6.component';
import { UserRegistrationS7Component } from './pages/user-registration/first-time-registration/user-registration-s7/user-registration-s7.component';
import { UserModifyRegistrationS1Component } from './pages/user-registration/modify-registration/user-modify-registration-s1/user-modify-registration-s1.component';
import { UserModifyRegistrationS2Component } from './pages/user-registration/modify-registration/user-modify-registration-s2/user-modify-registration-s2.component';
import { UserModifyRegistrationS3Component } from './pages/user-registration/modify-registration/user-modify-registration-s3/user-modify-registration-s3.component';
import { UserModifyRegistrationS4Component } from './pages/user-registration/modify-registration/user-modify-registration-s4/user-modify-registration-s4.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { ForgotPasswordComponent } from './pages/password-reset/forgot-password/forgot-password.component';
import { UnlockAccountComponent } from './pages/password-reset/unlock-account/unlock-account.component';
import { ForgotPasswordS1Component } from './pages/password-reset/forgot-password/forgot-password-s1/forgot-password-s1.component';
import { ForgotPasswordS2Component } from './pages/password-reset/forgot-password/forgot-password-s2/forgot-password-s2.component';
import { ForgotPasswordS3Component } from './pages/password-reset/forgot-password/forgot-password-s3/forgot-password-s3.component';
import { ForgotPasswordS4Component } from './pages/password-reset/forgot-password/forgot-password-s4/forgot-password-s4.component';
import { ForgotPasswordS5Component } from './pages/password-reset/forgot-password/forgot-password-s5/forgot-password-s5.component';
import { ForgotPasswordS6Component } from './pages/password-reset/forgot-password/forgot-password-s6/forgot-password-s6.component';
import { ForgotPasswordS7Component } from './pages/password-reset/forgot-password/forgot-password-s7/forgot-password-s7.component';
import { ForgotPasswordS8Component } from './pages/password-reset/forgot-password/forgot-password-s8/forgot-password-s8.component';
import { UnlockAccountS1Component } from './pages/password-reset/unlock-account/unlock-account-s1/unlock-account-s1.component';
import { UnlockAccountS2Component } from './pages/password-reset/unlock-account/unlock-account-s2/unlock-account-s2.component';
import { UnlockAccountS3Component } from './pages/password-reset/unlock-account/unlock-account-s3/unlock-account-s3.component';
import { UnlockAccountS4Component } from './pages/password-reset/unlock-account/unlock-account-s4/unlock-account-s4.component';
import { UnlockAccountS5Component } from './pages/password-reset/unlock-account/unlock-account-s5/unlock-account-s5.component';
import { UnlockAccountS6Component } from './pages/password-reset/unlock-account/unlock-account-s6/unlock-account-s6.component';
import { ForgotPasswordS9Component } from './pages/password-reset/forgot-password/forgot-password-s9/forgot-password-s9.component';
import { ForgotPasswordS10Component } from './pages/password-reset/forgot-password/forgot-password-s10/forgot-password-s10.component';
import { ForgotPasswordS11Component } from './pages/password-reset/forgot-password/forgot-password-s11/forgot-password-s11.component';


const routes: Routes = [
  {
    path: 'pages/landing-page',
    component: LandingPageComponent
  },
  { path: '', redirectTo: 'pages/landing-page', pathMatch: 'full' },
  // { path: 'pages/user-registration', component: UserRegistrationComponent },
  {
    path: 'pages/user-registration',
    component: UserRegistrationComponent,
    children: [
      {
        path: 'ftr',
        component: FirstTimeRegistrationComponent,
        children: [
          {
            path: '',
            redirectTo: '01',
            pathMatch: 'full'
          },
          {
            path: '01',
            component: UserRegistrationS1Component
          },
          {
            path: '02',
            component: UserRegistrationS2Component
          },
          {
            path: '03',
            component: UserRegistrationS3Component
          },
          {
            path: '04',
            component: UserRegistrationS4Component
          },
          {
            path: '05',
            component: UserRegistrationS5Component
          },
          {
            path: '06',
            component: UserRegistrationS6Component
          },
          {
            path: '07',
            component: UserRegistrationS7Component
          },
          {
            path: '08',
            component: UserRegistrationS8Component
          }
        ]
      },
      {
        path: 'mtr',
        component: ModifyRegistrationComponent,
        children: [
          {
            path: '',
            redirectTo: '01',
            pathMatch: 'full'
          },

          {
            path: '01',
            component: UserModifyRegistrationS1Component
          },
          {
            path: '02',
            component: UserModifyRegistrationS2Component
          },
          {
            path: '03',
            component: UserModifyRegistrationS3Component
          },
          {
            path: '04',
            component: UserModifyRegistrationS4Component
          },
          // {
          //   path: '',
          //   component: UserModifyRegistrationS1Component
          // }
        ]
      },
      {
        path: '',
        component: FirstTimeRegistrationComponent
      }
    ]
  },
  {
    path: 'pages/password-reset',
    component: PasswordResetComponent,
    children: [
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        children: [
          {
            path: '',
            redirectTo: '01',
            pathMatch: 'full'
          },
          {
            path: '01',
            component: ForgotPasswordS1Component
          },
          {
            path: '02',
            component: ForgotPasswordS2Component
          },
          {
            path: '03',
            component: ForgotPasswordS3Component
          },
          {
            path: '04',
            component: ForgotPasswordS4Component
          },
          {
            path: '05',
            component: ForgotPasswordS5Component
          },
          {
            path: '06',
            component: ForgotPasswordS6Component
          },
          {
            path: '07',
            component: ForgotPasswordS7Component
          },
          {
            path: '08',
            component: ForgotPasswordS8Component
          },
          {
            path: '09',
            component: ForgotPasswordS9Component
          },
          {
            path: '10',
            component: ForgotPasswordS10Component
          },
          {
            path: '11',
            component: ForgotPasswordS11Component
          }
        ]
      },
      {
        path: 'unlock-account',
        component: UnlockAccountComponent,
        children: [
          {
            path: '',
            redirectTo: '01',
            pathMatch: 'full'
          },
          {
            path: '01',
            component: UnlockAccountS1Component
          },
          {
            path: '02',
            component: UnlockAccountS2Component
          },
          {
            path: '03',
            component: UnlockAccountS3Component
          },
          {
            path: '04',
            component: UnlockAccountS4Component
          },
          {
            path: '05',
            component: UnlockAccountS5Component
          },
          {
            path: '06',
            component: UnlockAccountS6Component
          }
        ]
      },
      {
        path: '',
        component: ForgotPasswordComponent
      }
    ]
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
