import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-registration-s1',
  templateUrl: './user-registration-s1.component.html',
  styleUrls: ['./user-registration-s1.component.sass']
})
export class UserRegistrationS1Component implements OnInit {
  constructor(private router: Router) { }

  ngOnInit() {
  }

  buttonClick(): void {
    this.router.navigateByUrl('/pages/user-registration/ftr/02');
  }
}
