import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unlock-account-s5',
  templateUrl: './unlock-account-s5.component.html',
  styleUrls: ['./unlock-account-s5.component.sass']
})
export class UnlockAccountS5Component implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }
  buttonClick(): void {
    this.router.navigateByUrl('/pages/password-reset/unlock-account/06');
  }

}
