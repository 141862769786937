import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
// tslint:disable-next-line: max-line-length

import { UserRegistrationComponent } from './pages/user-registration/user-registration.component';

import { FirstTimeRegistrationComponent } from './pages/user-registration/first-time-registration/first-time-registration.component';
import { ModifyRegistrationComponent } from './pages/user-registration/modify-registration/modify-registration.component';
import { UserRegistrationS1Component } from './pages/user-registration/first-time-registration/user-registration-s1/user-registration-s1.component';
import { UserRegistrationS2Component } from './pages/user-registration/first-time-registration/user-registration-s2/user-registration-s2.component';
import { UserRegistrationS3Component } from './pages/user-registration/first-time-registration/user-registration-s3/user-registration-s3.component';
import { UserRegistrationS4Component } from './pages/user-registration/first-time-registration/user-registration-s4/user-registration-s4.component';
import { UserRegistrationS5Component } from './pages/user-registration/first-time-registration/user-registration-s5/user-registration-s5.component';
import { UserRegistrationS6Component } from './pages/user-registration/first-time-registration/user-registration-s6/user-registration-s6.component';
import { UserRegistrationS7Component } from './pages/user-registration/first-time-registration/user-registration-s7/user-registration-s7.component';
import { UserRegistrationS8Component } from './pages/user-registration/first-time-registration/user-registration-s8/user-registration-s8.component';
import { UserModifyRegistrationS1Component } from './pages/user-registration/modify-registration/user-modify-registration-s1/user-modify-registration-s1.component';
import { UserModifyRegistrationS2Component } from './pages/user-registration/modify-registration/user-modify-registration-s2/user-modify-registration-s2.component';
import { UserModifyRegistrationS3Component } from './pages/user-registration/modify-registration/user-modify-registration-s3/user-modify-registration-s3.component';
import { UserModifyRegistrationS4Component } from './pages/user-registration/modify-registration/user-modify-registration-s4/user-modify-registration-s4.component';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { ForgotPasswordComponent } from './pages/password-reset/forgot-password/forgot-password.component';
import { UnlockAccountComponent } from './pages/password-reset/unlock-account/unlock-account.component';
import { ForgotPasswordS1Component } from './pages/password-reset/forgot-password/forgot-password-s1/forgot-password-s1.component';
import { ForgotPasswordS2Component } from './pages/password-reset/forgot-password/forgot-password-s2/forgot-password-s2.component';
import { ForgotPasswordS3Component } from './pages/password-reset/forgot-password/forgot-password-s3/forgot-password-s3.component';
import { ForgotPasswordS4Component } from './pages/password-reset/forgot-password/forgot-password-s4/forgot-password-s4.component';
import { ForgotPasswordS5Component } from './pages/password-reset/forgot-password/forgot-password-s5/forgot-password-s5.component';
import { ForgotPasswordS6Component } from './pages/password-reset/forgot-password/forgot-password-s6/forgot-password-s6.component';
import { ForgotPasswordS7Component } from './pages/password-reset/forgot-password/forgot-password-s7/forgot-password-s7.component';
import { ForgotPasswordS8Component } from './pages/password-reset/forgot-password/forgot-password-s8/forgot-password-s8.component';
import { ForgotPasswordS9Component } from './pages/password-reset/forgot-password/forgot-password-s9/forgot-password-s9.component';
import { ForgotPasswordS10Component } from './pages/password-reset/forgot-password/forgot-password-s10/forgot-password-s10.component';
import { ForgotPasswordS11Component } from './pages/password-reset/forgot-password/forgot-password-s11/forgot-password-s11.component';
import { UnlockAccountS1Component } from './pages/password-reset/unlock-account/unlock-account-s1/unlock-account-s1.component';
import { UnlockAccountS2Component } from './pages/password-reset/unlock-account/unlock-account-s2/unlock-account-s2.component';
import { UnlockAccountS3Component } from './pages/password-reset/unlock-account/unlock-account-s3/unlock-account-s3.component';
import { UnlockAccountS4Component } from './pages/password-reset/unlock-account/unlock-account-s4/unlock-account-s4.component';
import { UnlockAccountS5Component } from './pages/password-reset/unlock-account/unlock-account-s5/unlock-account-s5.component';
import { UnlockAccountS6Component } from './pages/password-reset/unlock-account/unlock-account-s6/unlock-account-s6.component';


// import { UserRegistrationS1Component } from './pages/user-registration/user-registration-s1.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,

    UserRegistrationComponent,
    FirstTimeRegistrationComponent,
    ModifyRegistrationComponent,
    UserRegistrationS1Component,
    UserRegistrationS2Component,
    UserRegistrationS3Component,
    UserRegistrationS4Component,
    UserRegistrationS5Component,
    UserRegistrationS6Component,
    UserRegistrationS7Component,
    UserRegistrationS8Component,
    UserModifyRegistrationS1Component,
    UserModifyRegistrationS2Component,
    UserModifyRegistrationS3Component,
    UserModifyRegistrationS4Component,
    PasswordResetComponent,
    ForgotPasswordComponent,
    UnlockAccountComponent,
    ForgotPasswordS1Component,
    ForgotPasswordS2Component,
    ForgotPasswordS3Component,
    ForgotPasswordS4Component,
    ForgotPasswordS5Component,
    ForgotPasswordS6Component,
    ForgotPasswordS7Component,
    ForgotPasswordS8Component,
    ForgotPasswordS9Component,
    ForgotPasswordS10Component,
    ForgotPasswordS11Component,
    UnlockAccountS1Component,
    UnlockAccountS2Component,
    UnlockAccountS3Component,
    UnlockAccountS4Component,
    UnlockAccountS5Component,
    UnlockAccountS6Component,


    // UserRegistrationS1Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFontAwesomeModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
