import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-registration-s4',
  templateUrl: './user-registration-s4.component.html',
  styleUrls: ['./user-registration-s4.component.sass']
})
export class UserRegistrationS4Component implements OnInit {

  constructor(private router: Router) { }


  ngOnInit() {
  }

  buttonClick(): void {
    this.router.navigateByUrl('/pages/user-registration/ftr/05');
  }
}
