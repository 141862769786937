import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-modify-registration-s4',
  templateUrl: './user-modify-registration-s4.component.html',
  styleUrls: ['./user-modify-registration-s4.component.sass']
})
export class UserModifyRegistrationS4Component implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  modifyLink(): void {
    window.location.href = 'https://aka.ms/ssprsetup?whr=kbr.com';
  }
}
