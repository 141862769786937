import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unlock-account-s4',
  templateUrl: './unlock-account-s4.component.html',
  styleUrls: ['./unlock-account-s4.component.sass']
})
export class UnlockAccountS4Component implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  buttonClick(): void {
    this.router.navigateByUrl('/pages/password-reset/unlock-account/05');
  }

}
