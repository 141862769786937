import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.sass']
})
export class LandingPageComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  UserRegister(): void {
    this.router.navigateByUrl('pages/user-registration/ftr/01');
  }

  PasswordReset(): void {
    this.router.navigateByUrl('pages/password-reset/forgot-password/01');
  }

  registerLink(): void {
    window.location.href = 'https://aka.ms/ssprsetup?whr=kbr.com';
  }

  resetLink(): void {
    window.location.href = 'https://passwordreset.microsoftonline.com/?whr=kbr.com';
  }
}
