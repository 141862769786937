import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unlock-account-s1',
  templateUrl: './unlock-account-s1.component.html',
  styleUrls: ['./unlock-account-s1.component.sass']
})
export class UnlockAccountS1Component implements OnInit {


  constructor(private router: Router) { }

  ngOnInit() {
  }
  buttonClick(): void {
    this.router.navigate(['/pages/password-reset/unlock-account/02'])
  }
}
