import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-registration-s7',
  templateUrl: './user-registration-s7.component.html',
  styleUrls: ['./user-registration-s7.component.sass']
})
export class UserRegistrationS7Component implements OnInit {

  constructor(private router: Router) { }


  ngOnInit() {
  }

  buttonClick(): void {
    this.router.navigateByUrl('/pages/user-registration/ftr/08');
  }
}
