import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-registration',
  templateUrl: './user-registration.component.html',
  styleUrls: ['./user-registration.component.sass']
})

export class UserRegistrationComponent implements OnInit {
  show: boolean = false

  constructor() { }

  ngOnInit() {
  }
}


