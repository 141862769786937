import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password-s2',
  templateUrl: './forgot-password-s2.component.html',
  styleUrls: ['./forgot-password-s2.component.sass']
})
export class ForgotPasswordS2Component implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  buttonClick(): void {
    this.router.navigateByUrl('/pages/password-reset/forgot-password/03');
  }
}
