import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-registration-s6',
  templateUrl: './user-registration-s6.component.html',
  styleUrls: ['./user-registration-s6.component.sass']
})
export class UserRegistrationS6Component implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  buttonClick(): void {
    this.router.navigateByUrl('/pages/user-registration/ftr/07');
  }
}
